import React from "react";
import {
  PageSection,
  PageSectionBackdrop,
  PageSectionWrapper,
  PageSectionHeader,
} from "../components/common";
import { PageHeaderImage } from "../components/common/PageHeaderImage";
const ExtendSubscription = () => {
  return (
    <PageSectionWrapper style={{ minHeight: "1000px" }}>
      <PageSection bgColor="var(--pmbCararra)">
        <PageSectionBackdrop bgColor="var(--creamWhite)">
          <PageSectionHeader style={{ minHeight: "997px" }}>
            <PageHeaderImage />
            Just nu så är det inte möjligt att förlänga prenumerationer.
            Kontakta oss för mer information.
          </PageSectionHeader>
        </PageSectionBackdrop>
      </PageSection>
    </PageSectionWrapper>
  );
};
export default ExtendSubscription;
